<template>
  <div class="cinema_add">
<!--    <NavBar title="添加影厅" class="nav_bottom"></NavBar>-->
    <main>
      <el-empty v-if="emptyShow" description="还没有添加影厅呢"  image="https://img01.yzcdn.cn/vant/empty-image-default.png"></el-empty>
      <div class="item" v-for="v in cinemaArray" :key=v.id>
        <van-swipe-cell >
          <p>{{ v.name }}</p>
          <template #right>
            <div class="btns">
              <div class="look" @click="look(v)">报告</div>
              <div class="edit" @click="edit(v)">修改</div>
              <div class="del" @click="del(v.id)">删除</div>
            </div>
          </template>
        </van-swipe-cell>
      </div>
    </main>
    <div class="submit" v-if="subShow">
      <button class="btn_sub" @click="cinemaShow=true">添加</button>
    </div>

    <!--添加弹出层-->
    <van-dialog
        v-model="cinemaShow"
        :beforeClose="beforeAdd"
        :close-on-click-overlay="istrue"  >
      <van-field v-model="cinemaName" label="影院厅" placeholder="请输入..." />
    </van-dialog>

    <!--修改弹出层-->
    <div class="xiugai">
      <van-dialog
          title="影院厅"
          v-model="editShow"
          :beforeClose="beforeEdit"
          confirm-button-text="修改"
          :close-on-click-overlay="istrue"  >
        <van-field v-model="cinemaName"  />
      </van-dialog>
    </div>


  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import { Dialog,Field,SwipeCell,Toast } from 'vant';
import {AddHall, GetHallList, UpdataHall, DelHall} from '../axios/api'
import {Loading,Empty} from "element-ui";
export default {
  name: "CinemaAdd",
  created() {
    /*判断是否存在token 获取影厅列表*/
    if(localStorage.getItem("token")){
      let loadingInstance = Loading.service({ fullscreen: true , text: "加载中"});
      GetHallList({token:localStorage.getItem("token")}).then(respose=>{
        this.cinemaArray=respose.data || [];
        if(respose.data.length<=0){
          this.emptyShow=true
        }
        loadingInstance.close();
        this.subShow=true; // 解决从报告返回列表页闪烁问题，按钮默认隐藏，创建完毕后展示 （如果有需求可以用keep-alive）
      }).catch(error=>{
        console.log(error);
      })
    }else{
      // 没有用户token
      Toast({message:"非法操作，\n请重新登录",icon:"cross"})
      /*Message.error("非法操作，请重新登录");*/
      this.$router.push("/cinemaLogin")
    }
  },
  components:{
    NavBar,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]:Field,
    [SwipeCell.name]:SwipeCell,
    [Empty.name]:Empty
  },
  data(){
    return{
      cinemaArray:[],
      cinemaShow:false,
      cinemaName:'',
      oldCinemaName:'',
      editShow:false,
      one_cinema_id:'',
      istrue:true,
      emptyShow:false,
      subShow:false
    }
  },
  methods:{
    beforeAdd(action, done) {
      if (action === 'confirm') {
        /*判断用户是否已经输入内容*/
        if(this.cinemaName){
          if(this.cinemaArray.some(v=>v.name===this.cinemaName)){
            Toast.fail("已存在相同名字的影厅")
            done();
            this.cinemaName='';
          }else{
            //发起请求
            AddHall({name:this.cinemaName,token:localStorage.getItem("token")}).then(respose=>{
              if(respose.state==="1"){
                this.cinemaArray.push({name:this.cinemaName,id:respose.data.toString()})
                /*Message.success("添加成功");*/
                Toast.success("添加成功")
              }else{
                /*Message.error("添加失败");*/
                Toast({message:"添加失败"+respose?.msg,icon:"cross"})
              }
              done();
              this.cinemaName='';
            }).catch(error=>{
              console.log(error)
            })
          }
        }else{
          Toast.fail("请先输入影厅")
          /*Message.warning("请先输入影厅名字");*/
          done();
        }
      }else{
        done();
        this.cinemaName='';
      }
    },
    beforeEdit(action, done){
      if (action === 'confirm') {
        /*判断用户是否已经输入内容*/
        if(this.cinemaName){
          if(this.oldCinemaName===this.cinemaName){
            /*用户没有修改*/
            /*Message.warning("你还没有修改呢");*/
            Toast.fail("未修改")
            done();
            //清除内容
            this.cinemaName='';
          }else{
            /*如果修改后有相同名字*/
            if(this.cinemaArray.some(v=>v.name===this.cinemaName)){
              Toast.fail("已存在相同名字的影厅")
              done();
              //清除内容
              this.cinemaName='';
            }else{
              //发起请求
              UpdataHall({name:this.cinemaName,token:localStorage.getItem("token"),hid:this.one_cinema_id}).then(respose=>{
                if(respose.state==="1"){
                  //修改当前列表数组
                  this.cinemaArray.map(v=>{
                    if(v.id==this.one_cinema_id){
                      v.name=this.cinemaName
                    }
                  })
                  this.one_cinema_id='';
                  /*Message.success("修改成功");*/
                  Toast.success("修改成功")
                }else{
                  /*Message.error("修改失败");*/
                  Toast({message:"修改失败"+respose?.msg,icon:"cross"})
                }
                done();
                //清除内容
                this.cinemaName='';
              }).catch(error=>{
                console.log(error)
              })
            }
          }
        }else{
         /* Message.warning("请先输入影厅名字");*/
          Toast.fail("请先输入影厅名字")
          done();
        }
      }else{
        done();
        //清除内容
        this.cinemaName='';
      }
    },
    edit(v){
      this.editShow=true;
      this.cinemaName=v.name;
      this.oldCinemaName=v.name;
      this.one_cinema_id=v.id
    },
    del(id){
      Dialog.confirm({
        title: '提示',
        message: '删除后将无法找回，是否删除',
        getContainer : ".cinema_add",
        beforeClose
      }).then(()=>{})
      .catch(()=>{});

      const that = this;

      function beforeClose(action, done) {
        if (action === 'confirm') {
          DelHall({
            hid:id,
            token:localStorage.getItem("token")
          }).then(respose=>{
            if(respose.state==="1"){
              /*删除数组里数据 可以考虑用splice删除*/
              console.log(that.cinemaArray)
              that.cinemaArray=that.cinemaArray.filter(v=>{
                console.log(v,id)
                return v.id!=id;
              })
              /*Message.success("删除成功");*/
              Toast.success("删除成功")
            }else{
              /*Message.error("删除失败");*/
              Toast({message:"删除失败"+respose?.msg,icon:"cross"})
            }
            done();
          }).catch(error=>{
            console.log(error)
          })
        }else{
          done();
        }
      }
    },
    look(v){
      this.$router.push({name:"CinemaTestingResult",params:{id:v.id}})
    }
  },
  watch:{
    cinemaArray(){
      if(this.cinemaArray.length==0){
        this.emptyShow=true
      }else {
        this.emptyShow=false
      }
    }
  }
}
</script>

<style scoped>
.goods-card {
  margin: 0;
  background-color: white;
}

.delete-button {
  height: 100%;
}
main {
  padding-top: 36px;
  min-height: calc(100vh  - 36px - 166px);
}
.item {
  margin: 0 auto 22px;
  width: 690px;
  height: 150px;
  background: #FFFFFF;
  box-shadow: 0px 4px 13px 5px rgba(112, 112, 112, 0.06);
  border-radius: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item p {
  margin: 51px 248px 51px 44px;
  width: 387px;
  height: 48px;
  font-size: 34px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 40px;
}
.item .btns {
  width: 345px;
  height: 124px;
  margin-right: 13px;
  font-size: 32px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 3px;
  text-align: center;
  line-height: 124px;
  cursor: pointer;
}
.item .btns .look {
  float: left;
  width: 115px;
  height: 124px;
  background: #577BFF;
  border-radius: 3px 0px 0px 3px;
}
.item .btns .edit {
  float: left;
  width: 115px;
  height: 124px;
  background: #FFB444;
  border-radius: 3px 0px 0px 3px;
}
.item .btns .del {
  float: left;
  width: 115px;
  height: 124px;
  background: #FF4B4B;
  border-radius: 0px 3px 3px 0px;
}
.submit {
  width: 750px;
  height: 166px;
  background: #FFFFFF;
  box-shadow: 0 4px 13px 5px rgba(112, 112, 112, 0.06);
  text-align: center;
}
.submit .btn_sub {
  margin-top: 18px;
  width: 691px;
  height: 89px;
  border: 0;
  background: linear-gradient(270deg, #3054F8 0%, #4768FF 100%);
  box-shadow: 0 0 18px 3px rgba(66, 95, 220, 0.35);
  border-radius: 41px 41px 41px 41px;
  opacity: 0.87;
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #FFFFFF;
}
  .cinema_add>>>.van-dialog {
  width: 614px;

}
.cinema_add>>>.van-cell {
  margin: 137px 30px 175px 33px;
  width: calc(533px);
  height: 76px;
  background: #F2F2F2;
  border-radius: 10px;
  padding: 0 16px;
  align-items: center;
}
.cinema_add>>>.van-button__text {
  color: #5270FB;
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
}
.cinema_add>>>.van-dialog__footer {
  padding: 0;
  margin: 0 auto;
  width: 552px;
  height: 113px;
  border-top: 1px solid #F2F2F2;

  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #5270FB;
}
.cinema_add>>>.van-dialog__footer button{
  height: 113px;
  border: none!important;
  font-size: 16px;
}
.cinema_add>>>.van-field__label {
  width: 104px;
  height: 42px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #404040;
  line-height: 42px;
}
.cinema_add>>>.van-field__control {
  text-align: right;
  width: 100%;
  height: 45px;
  font-size: 32px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
}
.cinema_add>>>.van-hairline--top::after {
  border: 0px;
}
.cinema_add>>>.van-swipe-cell__right {
  top: -38px;
}
>>>.van-swipe-cell{
  width: 100%;
}
.xiugai>>>.van-cell {
  margin: 87px 30px 100px 33px;
}
>>>.van-button::before{
  background-color: transparent!important;
  border:none!important;
}

>>>.van-dialog__header{
  padding-top: 26px;
  padding-bottom: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}
</style>
